@import "node_modules/bootstrap-less/bootstrap/index";
@import "node_modules/font-awesome/less/font-awesome";
@import "node_modules/toastr/toastr";
@import "ng/ngDialog.min.css";
@import "ng/ngDialog-theme-default.min.css";
@import "ng/loading-bar.min.css";

// colors
@lightgray: #f2f2f2;
@middlegray: #D0D0D0;
@darkgray: #424F4E;
@yellow: #ffe556;
@white: #fff;
@red: #CE4C3E;
@primarycolor: #76827B;
@secondarycolor: #adcfcc;
@green: #93AC66;

// text-shadow
.textshadow {
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

//transition
.transition {
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

// fonts
.lato {
    font-family: 'Lato';
}

.cursive {
    font-family: 'Courgette', cursive;
}

// anchor style
a {
    color: @darkgray;
    text-decoration: none;
    .transition;
    &:hover {
        color: @primarycolor;
        text-decoration: none;
    }
    &:focus {
        color: @primarycolor;
        outline: 0 !important;
    }
}

// headlines
h1, h2, h3, h4, h5 {
    color: @primarycolor;
    font-weight: bold;
}

// headlines
h3 {
    color: @primarycolor;
    font-weight: bold;
}

//placeholder
.placeholder (@color) {
    &::-webkit-input-placeholder {
        color: @color;
    }
    &:-moz-placeholder {
        color: @color;
    }
    &::-moz-placeholder {
        color: @color;
    }
    &:-ms-input-placeholder {
        color: @color;
    }
}

// buttons
.stdbutton (@fontcolor, @bgcolor) {
    display: inline-block;
    padding: 5px 15px;
    margin: 0 5px;
    background: @bgcolor;
    color: @fontcolor;
    font-weight: bold;
    border: 0;
    border-radius: 2px;
    cursor: pointer;
    &:focus, &:active {
        text-decoration: none;
    }
    &:hover, {
        text-decoration: none;
        color: @fontcolor;
        background: darken(@bgcolor, 7%);
    }
}

.stdbutton-primary {
    display: inline-block;
    padding: 5px 15px;
    margin: 5px;
    background: @primarycolor;
    color: @white;
    font-weight: bold;
    border: 0;
    border-radius: 4px;
    .transition;
    &:focus, &:active, &:hover, {
        text-decoration: none;
        color: @white;
        background: darken(@primarycolor, 7%);
    }
    i {
        margin-right: 5px;
    }
}

.error {
    background-color: lighten(@red, 20%);
    color: #ffffff;
    border: 1px solid @red;
    text-align: center;
    padding: 20px;
    margin: 20px 0;
    border-radius: 4px;
}

form {
    input {
        margin-bottom: 10px;
    }
    .stdbutton-primary {
        display: block;
        margin: 20px auto 0;
    }
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.loader-wrapper img {
    display: block;
    margin: 30px auto 10px;
}

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    .lato;
    font-weight: 300;
    font-size: 16px;
    background: @lightgray;
    &.login {
        background: @primarycolor;
        background-attachment: fixed;
        .title {
            color: @white;
            .textshadow;
        }
        main {
            padding: 100px 0 0;
        }
    }
}

header {
    padding: 60px 0;
    background: @secondarycolor;
}

main.std {
    .container {
        margin-top: -30px;
        padding: 50px 20px;
        background: @white;
        border-radius: 4px;
        -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    }
}

footer {
    text-align: center;
    padding: 20px 0;
    img {
        height: 35px;
    }
}

.title {
    text-align: center;
    color: @darkgray;
    font-size: 70px;
    margin-bottom: 30px;
}

.logo {
    a {
        color: @white;
        .textshadow;
        font-family: 'Courgette', cursive;
        font-size: 2em;
    }
}

.btn {
    display: block;
    margin-top: 20px;
}

.btn-primary {
    color: #fff;
    font-weight: bold;
    background-color: @primarycolor !important;
    border: 0;
    padding: 15px 20px;
    margin: 20px auto;
    .transition;
    &:hover, &:focus, &:active {
        background-color: darken(@primarycolor, 10%) !important;
    }
}

label {}

.alert {
    margin: 30px 0;
}

nav.top {
    ul {
        margin: 0;
        padding: 0;
        li {
            padding: 12px 0;
            display: inline-block;
            a {
                color: @white;
                padding: 12px 20px;
                font-weight: bold;
                .textshadow;
                .transition;
                &:hover {
                    background: rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                }
            }
        }
    }
}


/* Login */

.loginform {
    input {
        color: @white;
        padding: 10px 12px;
        font-size: 1.1em;
        height: auto;
        background: transparent;
        &:focus {
            border-color: @white;
            outline: 0;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .3);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .3);
        }
        &[type=submit] {
            margin-top: 35px;
            color: @darkgray;
            font-weight: 700;
            background: @white !important;
            width: 100%;
            padding: 10px 40px;
            border-color: @white;
            border-radius: 23px;
            .transition;
            &:hover {
                background: @middlegray !important;
                border-color: @middlegray !important;
            }
            &:active {
                color: @darkgray;
                background: @lightgray;
                border-color: @lightgray;
                &:hover {
                    color: @darkgray;
                    background: @lightgray;
                    border-color: @lightgray;
                }
                &:focus {
                    outline: 0;
                    background: @lightgray;
                }
            }
        }
    }
    label {
        margin-top: 20px;
        color: @white;
    }
}


/* Tables */

table {
    color: @darkgray;
    margin: 20px auto;
    thead {
        font-size: 1.2em;
        color: @primarycolor;
        font-weight: bold;
        border-bottom: 1px solid @secondarycolor;
    }
    tr {
        line-height: 30px;
        border-bottom: 1px solid #eee;
        .transition;
        &:hover {
            i.fa-close {
                color: @red;
            }
        }
        &:last-child {
            border-bottom: 0;
        }
        td {
            padding: 5px 20px;
            vertical-align: top;
        }
    }
    i {
        color: @darkgray;
        &.fa-plus-circle {
            color: @green;
            margin-left: 15px;
            outline: 0;
        }
        &.fa-close {
            color: @red;
            font-weight: bold;
        }
    }
    &.userlist {
        tr:last-child {
            a {
                color: @primarycolor;
                .transition;
                &:hover {
                    color: darken(@primarycolor, 20%);
                    i.fa-plus-circle {
                        color: darken(@primarycolor, 20%);
                    }
                }
            }
            i.fa-plus-circle {
                .transition;
                color: @primarycolor;
                margin-right: 5px;
            }
        }
    }
    &.projectlist {
        tr:hover {
            span {
                background: darken(@lightgray, 10%);
            }
        }
        a.deleteButton {
            color: @darkgray;
            display: block;
            padding: 0 10px;
            margin: 3px;
            position: relative;
            border-radius: 2px;
            &:hover {
                color: @white;
                background: @red;
                &:after {
                    content: "\f057";
                    font-family: FontAwesome;
                    color: @white;
                    position: absolute;
                    right: 5px;
                }
            }
        }
        i.fa-user-plus {
            color: @green;
        }
    }
    &.add-user-to-project, &.add-user-to-organisation {
        width: 100%;
        tbody tr {
            cursor: pointer;
            &:hover {
                background: darken(@white, 10%)
            }
        }
        .fa-plus-circle {
            color: @green;
        }
    }
    .project-type {
        width: 1em;
        height: auto;
        margin-right: 5px;
        vertical-align: sub;
    }
}

.projectoverview {
    span {
        display: block;
    }
}

.dashboard {
    a {
        font-weight: bold;
    }
    i.fa-plus-circle {
        font-size: 1.5em;
        color: @green;
        cursor: pointer;
    }
    h3 {
        margin: 0;
    }
    hr {
        margin: 5px 0 10px;
        &.blue {
            border-top-color: @secondarycolor;
        }
    }
    a.quick-translate {
        .stdbutton(@white, @green);
        margin-bottom: 20px;
        img {
            margin-right: 10px;
        }
    }
    ul.listprojects, ul.listorganisations {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            margin-bottom: 5px;
            padding: 5px;
            .userrole {
                font-weight: 400;
                color: lighten(@darkgray, 30%);
            }
            img {
                width: auto;
                height: 1em;
                vertical-align: text-bottom;
                margin-right: 5px;
            }
            &:hover {
                background: lighten(@secondarycolor, 20%)
            }
        }
    }
}

a.link-translation {
    float: right;
    padding: 4px;
    margin-left: 5px;
    border-radius: 4px;
    background: @darkgray;
    color: @white;
    font-size: 0.9em;
    line-height: 1em;
    font-weight: bold;
    &:hover, &:active {
        text-decoration: none;
    }
}

.dropzone {
    display: block;
    margin: 20px 0;
    .dz-message {
        border-radius: 5px;
        border: 2px dotted @primarycolor;
        vertical-align: middle;
        display: block;
        color: @primarycolor;
        cursor: pointer;
        height: 200px;
        padding-top: 50px;
        span {
            display: block;
            font-size: 2em;
        }
    }
    .dz-preview {
        display: none;
        visibility: hidden;
    }
}

.loader-spinner {
    background: url('../images/loader.gif');
    width: 16px;
    height: 16px;
    display: block;
    margin: 0 auto;
}

#instantparser {
    margin-top: -50px;
    .backtop {
        position: fixed;
        right: 10px;
        bottom: 10px;
        font-size: 2em;
        color: @primarycolor;
        background: transparent;
        i {
            margin: 0;
        }
    }
}

.action-bar {
    position: relative;
    background: darken(@white, 5%);
    border-bottom: 2px solid darken(@white, 10%);
    margin: 0 -35px 0;
    padding: 12px 0 10px;
    border-radius: 5px 5px 0 0;
    text-align: center;
    .search, .sort {
        display: inline-block;
    }
    &.fullwidth {
        width: 100% !important;
        left: 0 !important;
        margin: 0;
        border-radius: 0;
    }
    input {
        width: 150px;
        border: 0;
        outline: 0;
        border-bottom: 2px solid darken(@white, 5%);
        padding: 5px 10px 5px 10px;
        margin: 0 10px;
        border-radius: 5px;
        color: @secondarycolor;
        font-weight: bold;
        background: @white;
        text-align: center;
        .placeholder(darken(@white, 20%));
        .transition;
        &.has-content {
            border-bottom: 2px solid @secondarycolor;
        }
    }
    i.fa-search {
        position: absolute;
        top: 7px;
        left: 17px;
        font-size: 2.5em;
        color: darken(@white, 10%);
        .transition;
        &.has-content {
            color: @secondarycolor;
        }
    }
    a.sort-translations {
        .stdbutton(@secondarycolor, @white);
        margin-left: -2px;
        border-radius: 0 4px 4px 0;
    }
    a.save-instantparser {
        .stdbutton(@white, @secondarycolor);
    }
    a.add-translation {
        .stdbutton(@white, @green);
    }
    .dropdown-sort {
        position: relative;
        display: inline-block;
        button#sort-value {
            .stdbutton(@secondarycolor, @white);
            margin-right: 0;
            border-radius: 4px 0 0 4px;
            width: 135px;
        }
        ul.dropdown-menu {
            font-weight: bold;
            border: 0;
            left: 5px;
            border-radius: 0 0 4px 4px;
            min-width: 135px;
            li {
                width: 135px;
                a {
                    color: @secondarycolor;
                    font-weight: bold;
                }
            }
        }
    }
    .dropdown-tt {
        display: inline-block;
        position: absolute;
        right: 2px;
        top: 12px;
        button {
            .stdbutton(@secondarycolor, @white);
            padding: 7px 10px 5px 10px;
        }
        &.open button {
            background: darken(@lightgray, 5%);
            border-color: darken(@lightgray, 5%) !important;
        }
        ul.dropdown-menu {
            right: 0;
            left: initial;
            min-width: 100px;
            background: transparent;
            border: 0;
            border-radius: 0 0 4px 4px;
            border-bottom: 2px solid darken(@white, 10%);
            -webkit-box-shadow: none;
            box-shadow: none;
            margin-top: 14px;
            background: @lightgray;
            padding: 5px;
            i {
                margin-right: 5px;
            }
            li {
                min-width: 110px;
                a {
                    display: block !important;
                    .stdbutton(@white, @primarycolor);
                    margin: 5px 0 0;
                    padding: 5px 0;
                    text-align: center;
                    &.delete {
                        .stdbutton(@white, @red);
                        margin: 5px 0 0;
                    }
                }
                &.confirmdeletetranslation {
                    text-align: center;
                    font-weight: bold;
                    color: @darkgray;
                    display: none;
                    margin-top: 10px;
                    a {
                        .stdbutton(@white, @red);
                        margin: 5px 0 0;
                        padding: 5px 0;
                        &.abort {
                            .stdbutton(@white, @darkgray);
                            margin: 5px 0 0;
                        }
                    }
                }
            }
        }
    }
    #choose-language {
        display: none;
        margin-top: 20px;
        .btn {
            border: 1px solid @green;
            &.selected {
                color: @green;
                background: @white;
            }
        }
        .toggle-choose-language {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -20px;
            margin: 0 auto;
            font-size: 18px;
            color: @primarycolor;
            background: @lightgray;
            display: block;
            width: 30px;
            height: 18px;
            border-radius: 0 0 4px 4px;
            border: 1px solid darken(@white, 10%);
            border-top: 0;
            cursor: pointer;
            z-index: 99;
        }
    }
}

#edit-languages {
    display: none;
    background: @white;
    padding: 10px 0;
    margin-top: 10px;
}

#create-translation {
    h2 {
        color: @primarycolor;
        margin-bottom: 30px;
        font-weight: bold;
    }
    a {
        margin: 0 auto;
    }
    .toggleCreate {
        display: none;
    }
    #toggle-create-btn, .create-new-translation {
        .stdbutton(@white, @primarycolor);
        margin: 20px auto;
    }
}

#choose-language {
    text-align: center;
    .btn {
        .stdbutton(@white, @green);
        margin-top: 7px;
    }
    #languageJSON {
        display: none;
    }
    .lang {
        margin-bottom: 5px;
        border-radius: 2px;
        padding: 5px;
        div {
            padding: 0;
        }
        input {
            border: 0;
            border-radius: 0;
            outline: 0;
            margin: 0;
            box-shadow: none;
            background: @secondarycolor;
            color: @white;
            font-weight: bold;
            .textshadow;
            .placeholder(darken(@white, 10%));
            &.shortcode {
                border-radius: 2px 0 0 2px;
            }
        }
        .delete-lang {
            background: lighten(@secondarycolor,10%);
            i {
                border-radius: 0 2px 2px 0;
                display: block;
                padding: 9px 7px;
                color: @red;
                cursor: pointer;
            }
            &:hover i {
                background: @red;
                color: @white;
            }
        }
    }
}

#parsedcontent {
    .label, .group, .editor, .updated_at {
        margin: 15px 0 0 0;
        padding: 10px 0 0;
        background: @white;
        text-align: center;
        color: @primarycolor;
        font-weight: bold;
        i {
            .textshadow;
        }
        input {
            font-weight: bold;
            width: 90%;
            height: 34px !important;
            padding: 5px;
            border: 0;
            background: @white;
            text-align: center;
            resize: none;
            .textshadow;
            &:focus {
                background: darken(@white, 5%);
            }
            .placeholder(lighten(@primarycolor, 25%));
        }
    }
    .editor {
        display: inline-block;
        padding: 16px 0;
        .textshadow;
    }
    .updated_at {
        display: inline-block;
        padding: 16px 0;
        border-radius: 0 4px 4px 0;
        .textshadow;
    }
    .group {
        border-radius: 4px 0 0 4px;
    }
    .description {
        margin-bottom: 10px;
        input {
            color: @primarycolor;
            background: darken(@white, 5%);
            padding: 5px 10px;
            width: 100%;
            outline: 0;
            border: 0;
            font-style: italic;
            border-top: 1px solid darken(@white, 10%);
            font-weight: bold;
            font-size: 0.95em;
            &::-webkit-input-placeholder {
                color: lighten(@primarycolor, 25%);
                font-weight: normal;
            }
            &:-moz-placeholder {
                color: lighten(@primarycolor, 25%);
                font-weight: normal;
            }
            &::-moz-placeholder {
                color: lighten(@primarycolor, 25%);
                font-weight: normal;
            }
            &:-ms-input-placeholder {
                color: lighten(@primarycolor, 25%);
                font-weight: normal;
            }
        }
    }
    textarea {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        resize: none;
        width: 100%;
        height: 100%;
        padding: 5px 10px;
        border: 0;
        border-left: 25px solid @primarycolor;
        background: @lightgray, ;
        resize: none;
        color: @primarycolor;
        &:focus {
            .transition;
            outline: 0;
            background: @lightgray;
        }
    }
    .lang-wrapper {
        padding: 0 !important;
        position: relative;
        margin-bottom: 2px;
    }
    .textarea-wrapper .lang-info {
        color: @white;
        font-size: 0.8em;
        font-weight: bold;
        transform: rotate(-90deg);
        transform-origin: 0;
        width: 42px;
        height: 25px;
        line-height: 25px;
        position: absolute;
        left: 12px;
        top: 40px;
        text-align: right;
    }
    .labelerror input {
        -webkit-box-shadow: inset 0px 0px 10px 0px @red;
        -moz-box-shadow: inset 0px 0px 10px 0px @red;
    }
    .translation-actions {
        text-align: right;
        a {
            color: lighten(@primarycolor, 15%);
            cursor: pointer;
            margin: 5px;
            display: inline-block;
        }
        .action-wrapper {
            display: inline-block;
            display: none;
            margin-right: 20px;
        }
        .toggle-action-wrapper {
            position: absolute;
            right: 10px;
            top: 10px;
            color: lighten(@primarycolor, 15%);
            cursor: pointer;
        }
    }
    .multidim {
        position: relative;
        border: 2px solid @lightgray;
        margin-bottom: 5px;
        padding: 0 15px;
        span {
            position: relative;
            display: inline-block;
            padding: 5px 0 5px 15px;
            font-weight: bold;
            cursor: pointer;
            color: @primarycolor;
            &.expanded:before {
                font-family: FontAwesome;
                content: "\f146";
                display: block;
                position: absolute;
                left: -10px;
            }
            &:before {
                font-family: FontAwesome;
                content: "\f0fe";
                display: block;
                position: absolute;
                left: -10px;
            }
        }
        .multidim-translations {
            max-height: inherit;
            .transition();
            overflow: hidden;
            &.hidden {
                max-height: 0;
            }
        }
    }
}

.dropdown {
    &.dropdown-std {
        ul.dropdown-menu {
            left: 0;
            right: 0;
            float: none;
            margin: 2px auto;
            padding: 10px;
            width: 250px;
            color: @darkgray;
            a {
                color: @darkgray;
            }
            i {
                margin-right: 5px;
            }
        }
    }
}

.dropdown-parser-view {
    position: absolute;
    right: 10px;
    display: inline-block;
    button {
        color: @white;
        background: @secondarycolor;
        display: inline-block;
        padding: 5px 15px;
        margin: 0 5px;
        font-weight: bold;
        border: 0;
        border-radius: 2px;
        cursor: pointer;
        padding: 2px 5px 0px 5px;
        i {
            color: @white;
        }
        .transition;
        &:hover, &:focus, &:active {
            outline: 0 !important;
            background: darken(@secondarycolor, 10%) !important;
            color: @white;
        }
    }
    .confirmdeletetranslation {
        text-align: center;
        color: @darkgray;
        display: none;
        padding: 10px;
        a {
            border-radius: 4px;
            margin: 5px 0;
            &.confirm {
                color: @white;
                background: @red;
                margin-top: 15px;
                &:hover {
                    background: darken(@red, 10%);
                }
            }
        }
    }
    .dropdown-menu {
        border: 0;
        top: 2em;
        left: -130px;
        li {
            margin: 5px;
            a {
                .stdbutton(@white, @primarycolor);
                margin: 5px;
                cursor: pointer;
                display: block;
                i {
                    margin-right: 5px;
                }
            }
            &.deletetranslation {
                a {
                    .stdbutton(@white, @red);
                }
            }
        }
    }
}

.delete-dropdown {
    position: relative !important;
    padding: 0;
    button {
        display: inline-block;
        color: @white;
        margin: 0 5px !important;
        padding: 0;
        border: 0;
        background: transparent;
        .transition;
        &:hover, &:focus, &:active {
            outline: 0 !important;
            color: darken(@white, 10%) !important;
            background: transparent !important;
        }
    }
    ul {
        border: 0;
        top: 2.5em;
        left: -130px;
        font-weight: bold;
        li {
            text-align: center;
            color: @darkgray;
            padding: 10px;
            line-height: 1.2em;
            a {
                border-radius: 4px;
                margin: 5px 0;
                color: @red;
                &.confirm {
                    color: @white;
                    background: @red;
                    margin-top: 15px;
                    &:hover {
                        color: @white;
                        background: darken(@red, 10%);
                    }
                    i {
                        color: @white;
                    }
                }
                &.abort {
                    color: @white;
                    background: @darkgray;
                    &:hover {
                        color: @white;
                        background: darken(@darkgray, 10%);
                    }
                    i {
                        color: @white;
                    }
                }
            }
            i {
                margin-right: 5px;
            }
        }
    }
}

.saveddialog {
    text-align: center;
    color: @primarycolor;
    margin: 20px 0;
    span {
        display: block;
        font-weight: bold;
        margin-bottom: 20px;
    }
}


/* Confirmbox */

.confirmbox {
    text-align: center;
    .btn {
        display: inline-block;
        margin: 0 auto;
        width: 100px;
        margin: 20px 20px;
        color: @white;
        font-weight: bold;
        &.btn-yes {
            background: @primarycolor;
        }
        &.btn-no {
            background: @red;
        }
    }
}


/* Alerts */

.alert {
    text-align: center;
}


/* NG */

.ngdialog.ngdialog-theme-default .ngdialog-content {
    width: 600px !important;
    padding: 30px 1em;
}

.ngdialog {
    table {
        font-size: 0.9em;
        i {
            color: @primarycolor;
        }
        button {
            background: transparent;
            border: 0;
        }
    }
}

.whole-container {
    overflow-y: scroll;
    height: 100%;
    max-height: 100%;
    display: block;
    position: relative;
}

html.ngdialog-open {
    overflow: visible;
}


/* Bootstrap Changes */

.form-control:focus {
    border-color: @primarycolor;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(19, 78, 49, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(19, 78, 49, .6);
}

#create-project, #create-user {
    label {
        margin-top: 10px;
    }
    input {
        font-weight: bold;
    }
    select {
        margin-bottom: 10px;
    }
    .input-abs {
        position: absolute;
        margin-top: -43px;
        right: 16px;
        padding: 5px 20px;
        background: darken(@white, 5%);
        font-style: italic;
        border-radius: 0 4px 4px 0;
    }
    button.dropdown-toggle {
        .stdbutton(@darkgray, darken(@white, 10%));
        padding: 7px 12px; 
        width: 100%;
        margin: 0;
    }
    ul.dropdown-menu {
        left: 15px;
        padding: 10px;
        ul.results {
            max-height: 200px;
            width: 200px ;
            overflow: auto;
            list-style: none;
            padding: 0;
            li.result {
                cursor: pointer;
                padding: 3px 5px;
                font-weight: bold;
                &:hover {
                    background: darken(@white, 10%)
                }
            }
        }
    }
}

.project-title {
    position: relative;
    display: block;
    padding-bottom: 10px;
    font-weight: bold;
    color: lighten(@primarycolor, 15%);
    text-align: center;
    span {
        color: @primarycolor;
        font-size: 2em;
        display: block;
    }
    .type-container {
        position: absolute;
        left: 0;
        top: 10px;
        img {
            width: auto;
            height: 2em;
            vertical-align: bottom;
        }
        .type {
            display: inline-block;
            padding: 0 7px;
            background: @darkgray;
            color: @white;
            height: 2em;
            line-height: 2em;
            border-radius: 4px;
            margin-left: 5px;
            margin-top: 3px;
            a {
                color: @white;
            }
        }
    }
    .edit {
        position: absolute;
        right: 0;
        top: 10px;
        a {
            display: inline-block;
            padding: 0 7px;
            background: @darkgray;
            color: @white;
            height: 2em;
            line-height: 2em;
            border-radius: 4px;
            margin-left: 5px;
            margin-top: 3px;
            cursor: pointer;
        }
    }
}
.project-show {
    p.language {
        text-align: center;
        span {
            margin: 10px;
        }
    }
}